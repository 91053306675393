import keyMirror from 'keymirror'

export const ActorsConstants = keyMirror({
  FETCH_ALL_ACTORS_REQUEST: null,
  FETCH_ALL_ACTORS_SUCCESS: null,
  FETCH_ALL_ACTORS_FAILURE: null,

  FETCH_ACTOR_GROUPS_REQUEST: null,
  FETCH_ACTOR_GROUPS_SUCCESS: null,
  FETCH_ACTOR_GROUPS_FAILURE: null,

  FILTER_BY_GROUP: null,

  UPDATE_ACTOR_REQUEST: null,
  UPDATE_ACTOR_SUCCESS: null,
  UPDATE_ACTOR_FAILURE: null,

  FETCH_ACTORS_DATA_REQUEST: null,
  FETCH_ACTORS_DATA_SUCCESS: null,
  FETCH_ACTORS_DATA_FAILURE: null,

  CLEAR_ACTOR_DATA: null,

  FETCH_ACTORS_GROUPS_REQUEST: null,
  FETCH_ACTORS_GROUPS_SUCCESS: null,
  FETCH_ACTORS_GROUPS_FAILURE: null,

  FETCH_ACTORS_PERMS_REQUEST: null,
  FETCH_ACTORS_PERMS_SUCCESS: null,
  FETCH_ACTORS_PERMS_FAILURE: null,

  REMOVE_PERM_REQUEST: null,
  REMOVE_PERM_SUCCESS: null,
  REMOVE_PERM_FAILURE: null,

  ADD_PERM_REQUEST: null,
  ADD_PERM_SUCCESS: null,
  ADD_PERM_FAILURE: null,

  CREATE_ACTOR_REQUEST: null,
  CREATE_ACTOR_SUCCESS: null,
  CREATE_ACTOR_FAILURE: null,

  FETCH_ACTORS_IN_GROUP_REQUEST: null,
  FETCH_ACTORS_IN_GROUP_SUCCESS: null,
  FETCH_ACTORS_IN_GROUP_FAILURE: null,

  UPDATE_ASSIGNED_PERM_REQUEST: null,
  UPDATE_ASSIGNED_PERM_SUCCESS: null,
  UPDATE_ASSIGNED_PERM_FAILURE: null,

  REMOVE_ACTOR_REQUEST: null,
  REMOVE_ACTOR_SUCCESS: null,
  REMOVE_ACTOR_FAILURE: null,

  REMOVE_ACTORS_REQUEST: null,
  REMOVE_ACTORS_SUCCESS: null,
  REMOVE_ACTORS_FAILURE: null,

  FETCH_SERVICE_DATA_REQUEST: null,
  FETCH_SERVICE_DATA_SUCCESS: null,
  FETCH_SERVICE_DATA_FAILURE: null,

  VERIFY_SERVICE_REQUEST: null,
  VERIFY_SERVICE_SUCCESS: null,
  VERIFY_SERVICE_FAILURE: null,

  GET_BACKEND_SERVICES_REQUEST: null,
  GET_BACKEND_SERVICES_SUCCESS: null,
  GET_BACKEND_SERVICES_FAILURE: null,

  GET_FRONTEND_SERVICES_REQUEST: null,
  GET_FRONTEND_SERVICES_SUCCESS: null,
  GET_FRONTEND_SERVICES_FAILURE: null,

  GET_PARTITIONS_REQUEST: null,
  GET_PARTITIONS_SUCCESS: null,
  GET_PARTITIONS_FAILURE: null,

  FETCH_PHANTOM_CONNECTIONS_REQUEST: null,
  FETCH_PHANTOM_CONNECTIONS_SUCCESS: null,
  FETCH_PHANTOM_CONNECTIONS_FAILURE: null,

  CREATE_PHANTOM_CONNECTION_REQUEST: null,
  CREATE_PHANTOM_CONNECTION_SUCCESS: null,
  CREATE_PHANTOM_CONNECTION_FAILURE: null,

  REMOVE_PHANTOM_RELATION_REQUEST: null,
  REMOVE_PHANTOM_RELATION_SUCCESS: null,
  REMOVE_PHANTOM_RELATION_FAILURE: null,

  ADD_PERMISSIONS_REQUEST: null,
  ADD_PERMISSIONS_SUCCESS: null,
  ADD_PERMISSIONS_FAILURE: null
})
