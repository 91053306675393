import { merge } from 'ramda'

import { ActorsConstants } from '../constants/actionTypes'

const initialState = {
  fetching: false,
  permissions: [],
  listOfPerms: [],
  availablePerms: [],
  assignedPerms: [],
  compiledPerms: [],
  newPerms: []
}

export default (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    // FETCH ACTORS PERMS

    case ActorsConstants.FETCH_ACTORS_PERMS_REQUEST:
      return {
        ...state,
        fetching: true,
        permissions: [],
        listOfPerms: [],
        availablePerms: [],
        assignedPerms: [],
        compiledPerms: [],
        newPerms: [],
        error: ''
      }

    case ActorsConstants.FETCH_ACTORS_PERMS_SUCCESS:
      return {
        ...state,
        fetching: false,
        newPerms: payload.data
      }

    case ActorsConstants.FETCH_ACTORS_PERMS_FAILURE:
      return {
        ...state,
        fetching: false,
        permissions: [],
        listOfPerms: [],
        availablePerms: [],
        assignedPerms: [],
        compiledPerms: []
      }

    // REMOVE PERM

    case ActorsConstants.REMOVE_PERM_REQUEST:
      return {
        ...state,
        fetching: true
      }

    case ActorsConstants.REMOVE_PERM_SUCCESS:
      return {
        ...state,
        fetching: false
      }

    case ActorsConstants.REMOVE_PERM_FAILURE:
      return {
        ...state,
        fetching: false
      }

    // ADD PERM

    case ActorsConstants.ADD_PERMISSIONS_REQUEST:
      return {
        ...state,
        fetching: true
      }

    case ActorsConstants.ADD_PERMISSIONS_SUCCESS:
      return {
        ...state,
        fetching: false
        // listOfPerms: state.listOfPerms.concat(payload.newPerm),
        // availablePerms: state.availablePerms.filter(
        //   item => item.perm_id !== payload.defaultChangedPerm.perm_id
        // ),
        // assignedPerms: state.assignedPerms.concat(payload.newPerm)
      }

    case ActorsConstants.ADD_PERMISSIONS_FAILURE:
      return {
        ...state,
        fetching: false
      }

    // UPDATE ASSIGNED PERM

    case ActorsConstants.UPDATE_ASSIGNED_PERM_REQUEST:
      return {
        ...state,
        fetching: true
      }

    case ActorsConstants.UPDATE_ASSIGNED_PERM_SUCCESS:
      return {
        ...state,
        fetching: false,
        listOfPerms: state.listOfPerms,
        availablePerms: state.availablePerms,
        assignedPerms: state.assignedPerms.map(perm =>
          perm.perm_id === payload.perm.perm_id &&
          perm.actor_id === payload.perm.actor_id
            ? merge(perm, { perm_value: payload.value })
            : perm
        )
      }
    case ActorsConstants.UPDATE_ASSIGNED_PERM_FAILURE:
      return {
        ...state,
        fetching: false
      }

    default:
      return state
  }
}
