import { PermissionConstants } from './constants'

const initialState = {
  fetching: false,
  count: 0,
  data: null,
  error: null
}

export default (state = initialState, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case (PermissionConstants.GET_PERMISSION_INFO_REQUEST,
    PermissionConstants.REMOVE_PERMISSION_REQUEST):
      return {
        ...state,
        fetching: true
      }

    case PermissionConstants.REMOVE_PERMISSION_SUCCESS:
      return {
        ...state,
        fetching: false,
        count: state.count - 1,
        data: state.data.filter(item => item.uuid !== payload)
      }

    case PermissionConstants.REMOVE_PERMISSION_FAILURE:
      return {
        ...state,
        fetching: false,
        error: 'Error message'
      }
    case PermissionConstants.GET_PERMISSION_INFO_SUCCESS:
      return {
        ...state,
        fetching: false,
        count: payload.count,
        data: payload.permissions
      }
    case PermissionConstants.GET_PERMISSION_INFO_FAILURE:
      return {
        ...state,
        fetching: false,
        count: 0,
        data: null
        // error:
      }

    default:
      return state
  }
}
