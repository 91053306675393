import { AuthConstants } from '../constants';

const initialState = {
  fetching: false,
  data: '',
  error: ''
};

export default (state= initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case AuthConstants.GET_QR_CODE_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case AuthConstants.GET_QR_CODE_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: payload
      };

    case AuthConstants.GET_QR_CODE_FAILURE:
      return {
        ...state,
        fetching: false,
        error: payload
      };

    default: return state;
  }
};
