import { ActorsConstants } from '../constants/actionTypes';

const initialState = {
  fetching: true,
  serviceInfo: {},
  isVerified: '',
  error: ''
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ActorsConstants.FETCH_SERVICE_DATA_REQUEST:
      return {
        ...state,
        fetching: true,
        serviceInfo: {},
        error: ''
      };
    case ActorsConstants.FETCH_SERVICE_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        serviceInfo: payload,
        error: ''
      };
    case ActorsConstants.FETCH_SERVICE_DATA_FAILURE:
      return {
        ...state,
        fetching: false,
        serviceInfo: {},
        error: ''
      };

    case ActorsConstants.VERIFY_SERVICE_REQUEST:
      return {
        ...state,
        fetching: true,
        isVerified: false
      };
    case ActorsConstants.VERIFY_SERVICE_SUCCESS:
      return {
        ...state,
        fetching: false,
        isVerified: payload,
        error: ''
      };
    case ActorsConstants.VERIFY_SERVICE_FAILURE:
      return {
        ...state,
        fetching: false,
        isVerified: '',
        error: ''
      };

    default: return state;
  }
};
