import { ProfileConstants } from '../constants/index';

const initialState = {
  fetching: true,
  info: {}
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ProfileConstants.FETCH_PROFILE_INFO_REQUEST:
      return {
        ...state,
        fetching: true,
        info: {}
      };
    case ProfileConstants.FETCH_PROFILE_INFO_SUCCESS:
      return {
        ...state,
        fetching: false,
        info: payload
      };
    case ProfileConstants.FETCH_PROFILE_INFO_FAILURE:
      return {
        ...state,
        fetching: false,
        info: {}
      };

    default: return state;
  }
};
