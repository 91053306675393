import { AuthConstants } from '../constants';

const initialState = {
  fetching: false,
  listOfPolicies: [],
  message: ''
};

export default (state=initialState, action) => {
  switch (action.type) {
    case AuthConstants.GET_LIST_OF_POLICIES_REQUEST:
      return {
        ...state,
        fetching: true,
        listOfPolicies: [],
        message:''
      };

    case AuthConstants.GET_LIST_OF_POLICIES_SUCCESS:
      return {
        ...state,
        fetching: false,
        listOfPolicies: action.payload.list,
        message:''
      };

    case AuthConstants.GET_LIST_OF_POLICIES_FAILURE:
      return {
        ...state,
        fetching: false,
        listOfPolicies: [],
        message:'Error message'
      };

    default: return state;
  }
};
