import keyMirror from 'keymirror';

export const PermsConstants = keyMirror({
  FETCH_ALL_PERMS_REQUEST: null,
  FETCH_ALL_PERMS_SUCCESS: null,
  FETCH_ALL_PERMS_FAILURE: null,

  SAVE_SELECTED_ACTION_SUCCESS: null,
  SAVE_SELECTED_ACTION_FAILURE: null,

  UPDATE_PERMS_FOR_SERVICE_REQUEST: null,
  UPDATE_PERMS_FOR_SERVICE_SUCCESS: null,
  UPDATE_PERMS_FOR_SERVICE_FAILURE: null,

  ADD_PERMS_FOR_SERVICE_REQUEST: null,
  ADD_PERMS_FOR_SERVICE_SUCCESS: null,
  ADD_PERMS_FOR_SERVICE_FAILURE: null,

  FETCH_USERS_AND_GROUPS_REQUEST: null,
  FETCH_USERS_AND_GROUPS_SUCCESS: null,
  FETCH_USERS_AND_GROUPS_FAILURE: null,

  FETCH_DATA_FOR_PARTICULAR_ACTOR_REQUEST: null,
  FETCH_DATA_FOR_PARTICULAR_ACTOR_SUCCESS: null,
  FETCH_DATA_FOR_PARTICULAR_ACTOR_FAILURE: null,
})
