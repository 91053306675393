import { ActorsConstants } from '../constants/actionTypes';

const initialState = {
  fetching: false,
  listOfRelations: [],
  error: {}
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case ActorsConstants.FETCH_PHANTOM_CONNECTIONS_REQUEST:
      return {
        ...state,
        fetching: true,
        listOfRelations: [],
        error: {}
      };

    case ActorsConstants.CREATE_PHANTOM_CONNECTION_REQUEST:
    case ActorsConstants.REMOVE_PHANTOM_RELATION_REQUEST:
      return {
        ...state,
        fetching: true,
        error: {}
      };

    case ActorsConstants.FETCH_PHANTOM_CONNECTIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        listOfRelations: state.listOfRelations.concat(payload.relations),
        error: {}
      };

    case ActorsConstants.FETCH_PHANTOM_CONNECTIONS_FAILURE:
      return {
        ...state,
        fetching: true,
        listOfRelations: [],
        error: payload.error
      };

    case ActorsConstants.REMOVE_PHANTOM_RELATION_SUCCESS:
      return {
        ...state,
        fetching: false,
        listOfRelations: state.listOfRelations.filter(item => {
          for (let key in payload.removedRelations) {
            let removedItem = payload.removedRelations[key];
            if (
              item.uuid === removedItem.uuid
            )
              return false;
          }
          return true;
        })}

    case ActorsConstants.CREATE_PHANTOM_CONNECTION_SUCCESS:
      return {
        ...state,
        fetching: false,
        listOfRelations: state.listOfRelations.concat(payload.relationToAdd),
        error: {}
      };

    default: return state;
  }
};
