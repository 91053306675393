import React, { Suspense } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import App from './containers/app'
import Spinner from './components/spinner'
import { CookiesProvider } from 'react-cookie'
// import ErrorBoundary from './containers/error/ErrorBoundary'

import 'sanitize.css/sanitize.css'
import './fonts/noir-pro/styles.css'
import './index.css'
import './styles/main.css'
import './styles/custom.css'

const target = document.querySelector('#root')
// TODO: add ErrorBoundary as a wrapper for app
render(
  <CookiesProvider>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Suspense fallback={<Spinner />}>
          <div className="dt-sidebar--fixed dt-header--fixed dt-layout--full-width">
            <App />
          </div>
        </Suspense>
      </ConnectedRouter>
    </Provider>
  </CookiesProvider>,
  target
)
