import { createStore, applyMiddleware, compose } from 'redux'
import { connectRouter } from 'connected-react-router'
import thunkMiddleware from 'redux-thunk'
import { routerMiddleware }     from 'react-router-redux';
import { createBrowserHistory } from 'history';

import rootReducer from './modules';

export const history = createBrowserHistory()

const initialState = {}
const enhancers = []
const middleware = [thunkMiddleware, routerMiddleware(history)]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

export default createStore(
  connectRouter(history)(rootReducer),
  initialState,
  composedEnhancers
)
