import Cookies from 'universal-cookie'
import _ from 'lodash/core'
import moment from 'moment'

export const onChangeDateRaw = event => {
  event.preventDefault()
}

export const getUrlParams = url => {
  const params = {}
  const parser = document.createElement('a')

  parser.href = url

  const query = parser.search.substring(1)
  const vars = query.split('&')

  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')

    params[pair[0]] = decodeURIComponent(pair[1])
  }

  return params
}

export const getUrlWithFilterParams = (
  path,
  types = '',
  groups = '',
  name = '',
  startDate = '',
  endDate = ''
) => {
  const noTypes = types.length === 0

  const noName = name.length === 0

  const noDate = !startDate && !endDate

  const typesString = noTypes ? '' : `?types=${types}`

  const nameString = noName
    ? ''
    : `${noTypes && noDate ? '?' : '&'}name=${name}`

  const startDateString = !startDate
    ? ''
    : `${noTypes && noName ? '?' : '&'}start=${formatDate(startDate)}`

  const endDateString = !endDate
    ? ''
    : `${noTypes && noName && !startDate ? '?' : '&'}end=${formatDate(endDate)}`

  const groupsString =
    groups.length === 0
      ? ''
      : groups.includes('NOT BAN')
      ? `${noTypes ? '?' : '&'}groups__not=ban`
      : `${noTypes ? '?' : '&'}groups=${groups}`

  return `${path}${typesString}${groupsString}${nameString}${startDateString}${endDateString}`
}

export const formatDate = date => moment(date).format('YYYY-MM-DD')

export const formatDateTime = date =>
  moment(date, ['YYYY-MM-DD HH:mm', 'MMMM YYYY']).format('YYYY-MM-DD HH:mm')

export const isNumberFieldValid = value => !isNaN(+value)

export const isEmailFieldValid = value => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return value.length === 0 ? true : re.test(value)
}

export const isDomainFieldValid = value => {
  const re = /(?:^|[ \t])((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/gm

  return value.length === 0 ? true : re.test(value)
}

export const isPasswordFieldValid = value => value.length >= 6

export class AuthHelper {
  static getToken = () => {
    const cookies = new Cookies()

    return cookies.get('Auth')
  }

  static access() {
    let access = localStorage.getItem('access') || false
    return !!access
  }

  static authorizedAccess(data) {
    localStorage.setItem('access', data)
  }

  static clearAuthStorage() {
    localStorage.removeItem('access')
  }

  static isAuthorised = () => {
    const cookies = new Cookies()
    const authToken = cookies.get('Auth')
    return !!(authToken && authToken !== 'undefined')
  }

  static checkTemporaryToken = () => {
    try {
      const cookies = new Cookies()
      let allCookies = cookies.getAll()
      _.map(allCookies, (value, key) => {
        return key === 'temporary_session'
      })
    } catch (e) {
      // do nothing
    }
  }

  static removeTemporaryToken = () => {
    try {
      const cookies = new Cookies()
      let allCookies = cookies.getAll()
      _.map(allCookies, (value, key) => {
        if (key === 'temporary_session') {
          cookies.remove('temporary_session', { path: '/' })
        }
      })
    } catch (e) {
      // do nothing
    }
  }

  static setSession = token => {
    let cookies = new Cookies()
    let cookieName = 'Auth'
    cookies.set(cookieName, token, { path: '/' })
  }

  static removeSessionToken = name => {
    try {
      const cookies = new Cookies()
      let allCookies = cookies.getAll()
      _.map(allCookies, (value, key) => {
        if (key === name) {
          cookies.remove('Auth', { path: '/' })
        }
      })
    } catch (e) {
      console.log('removeSessionToken Error')
    }
  }
}
