import axios from 'axios';

import { AuthHelper } from '../../auth/utils';
import { logout, unauthorized } from '../../actors/actions/actorsActions';
import { ProfileConstants } from '../constants';
import { Notifier } from '../../perms/utils'
import { urlAPI } from '../../auth/actions';

export function fetchProfileInfo() {
  const token = AuthHelper.getToken();
  const config =  {
    headers: {
      'Content-Type': 'application/json',
      'Session-Token': token
    }
  }

  return dispatch => {
    const url = `${urlAPI}/actor/me`;
    dispatch({
      type: ProfileConstants.FETCH_PROFILE_INFO_REQUEST,
    });
    axios.get(url, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: ProfileConstants.FETCH_PROFILE_INFO_SUCCESS,
          payload: response.data
        });

        AuthHelper.authorizedAccess(response.data.access);
      }).catch(error => {
      dispatch({
        type: ProfileConstants.FETCH_PROFILE_INFO_FAILURE,
        payload: error.response ? error.response.data.message : {}
      });
      if(error.response.status === 401) {
        unauthorized(error.response.statusText);
        logout()
      }
    });
  };
}

export function updateProfile(data) {
  const token = AuthHelper.getToken();

  return dispatch => {
    const url = `${urlAPI}/actor/me`;

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Session-Token': token
      }
    };

    dispatch({ type: ProfileConstants.UPDATE_PROFILE_INFO_REQUEST });

    axios.put(url, data, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: ProfileConstants.UPDATE_PROFILE_INFO_SUCCESS,
          payload: response.data
        });

        Notifier.success('Updated successfully');
      }).catch(error => {
        dispatch({
          type: ProfileConstants.UPDATE_PROFILE_INFO_FAILURE,
          payload: error.response ? error.response.data.message : {}
        });
    });
  };
}

export function fetchListOfServices() {
  const token = AuthHelper.getToken();
  const config =  {
    headers: {
      'Content-Type': 'application/json',
      'Session-Token': token
    },
  }

  return dispatch => {
    const url = `${urlAPI}/services_info/`;
    dispatch({
      type: ProfileConstants.FETCH_SERVICES_REQUEST,
    });
    axios.get(url, config)
      .then(response => {
        dispatch({
          type: ProfileConstants.FETCH_SERVICES_SUCCESS,
          payload: response.data.services
        });
      }).catch(error => {
      dispatch({
        type: ProfileConstants.FETCH_SERVICES_FAILURE,
        payload: error.response ? error.response.data.message : {}
      });
      if(error.response && error.response.status === 401) {
        unauthorized(error.response.statusText);
        logout()
      }
    });
  };
}
