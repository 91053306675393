import {AuthConstants} from "../constants/index";


const initialState = {
  fetching: false,
  code: '',
};


export default (state=initialState, action) => {
  switch (action.type) {
    case AuthConstants.GET_QR_CODE_TO_DOWNLOAD_REQUEST:
      return {
        ...state,
        fetching: true,
        code: '',
      };
    case AuthConstants.GET_QR_CODE_TO_DOWNLOAD_SUCCESS:
      return {
        ...state,
        fetching: false,
        code:  action.data
      };
    case AuthConstants.GET_QR_CODE_TO_DOWNLOAD_FAILURE:
      return {
        ...state,
        fetching: false,
        code: '',
      };

    default:  return state;
  }
};
