import { ActorsConstants } from '../constants/actionTypes';

const initialState = {
  fetching: false,
  groupsInfo: [],
  error: ''
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ActorsConstants.FETCH_ACTORS_GROUPS_REQUEST:
      return {
        ...state,
        fetching: true,
        groupsInfo: [],
        error: ''
      };
    case ActorsConstants.FETCH_ACTORS_GROUPS_SUCCESS:
      return {
        ...state,
        fetching: false,
        groupsInfo: payload,
        error: ''
      };
    case ActorsConstants.FETCH_ACTORS_GROUPS_FAILURE:
      return {
        ...state,
        fetching: false,
        // groupsInfo: [],
        error: ''
      };
    default: return state;
  }
};
