import {AuthConstants} from "../constants/index";


const initialState = {
  fetching: false,
  data: {},
  message: ''
};

export default (state=initialState, action) => {
  switch (action.type) {
    case AuthConstants.CREATE_NEW_POLICY_REQUEST:
      return {
        ...state,
        fetching: true,
        data: {},
        message:''
      };
    case AuthConstants.CREATE_NEW_POLICY_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.data,
        message:''
      };
    case AuthConstants.CREATE_NEW_POLICY_FAILURE:
      return {
        ...state,
        fetching: false,
        data: {},
        message:'Error message'
      };

    default:  return state;
  }
};
