import {AuthConstants} from "../constants/index";


const initialState = {
  fetching: false,
  data: '',
  token: '',
  error: ''
};


export default (state=initialState, action) => {
  switch (action.type) {
    case AuthConstants.VERIFY_QR_CODE_REQUEST:
      return {
        ...state,
        fetching: true,
        data: '',
        token: '',
        error: ''
      };
    case AuthConstants.VERIFY_QR_CODE_SUCCESS:
      return {
        ...state,
        fetching: false,
        // data:  action.data.qr_code,
        // token:  action.data.qr_token,
        error: ''
      };
    case AuthConstants.VERIFY_QR_CODE_FAILURE:
      return {
        ...state,
        fetching: false,
        data: '',
        token:  '',
        error: action.data
      };

    default:  return state;
  }
};
