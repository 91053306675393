import { PermsConstants } from '../constants/actionTypes';

const initialState = {
  action: []
};


export default (state=initialState, action) => {
  switch (action.type) {
    case PermsConstants.SAVE_SELECTED_ACTION_SUCCESS:
      return state = {
        action: action.payload,
      };
    case PermsConstants.SAVE_SELECTED_ACTION_FAILURE:
      return state = {
        action: [],
      };
    default:  return state;
  }
};
