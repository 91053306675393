import { ProfileConstants } from '../constants/index';

const initialState = {
  fetching: false,
  services: []
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ProfileConstants.FETCH_SERVICES_REQUEST:
      return {
        ...state,
        fetching: true,
        services: []
      };
    case ProfileConstants.FETCH_SERVICES_SUCCESS:
      return {
        ...state,
        fetching: false,
        services: payload
      };
    case ProfileConstants.FETCH_SERVICES_FAILURE:
      return {
        ...state,
        fetching: false,
        services: []
      };

    default: return state;
  }
};
