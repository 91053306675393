import { PublicUIConstants } from '../constants/publicUITypes'

const initialState = {
  fetching: false,
  interfaces: [],
  interfaceData: {},
  error: ''
}

export default (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    // GET interfaces LIST

    case PublicUIConstants.FETCH_ALL_PUBLIC_UI_REQUEST:
      return {
        ...state,
        fetching: true,
        interfaces: []
      }

    case PublicUIConstants.FETCH_ALL_PUBLIC_UI_SUCCESS:
      return {
        ...state,
        fetching: false,
        interfaces: payload.interfaces
      }

    case PublicUIConstants.FETCH_ALL_PUBLIC_UI_FAILURE:
      return {
        ...state,
        fetching: false,
        error: ''
      }

    case PublicUIConstants.REMOVE_PUBLIC_UI_REQUEST:
      return {
        ...state,
        fetching: true,
        interfaces: state.interfaces
      }

    case PublicUIConstants.REMOVE_PUBLIC_UI_SUCCESS:
      return {
        ...state,
        fetching: false,
        interfaces: state.interfaces.filter(item => item.uuid !== payload)
      }

    case PublicUIConstants.REMOVE_PUBLIC_UI_FAILURE:
      return {
        ...state,
        fetching: false,
        interfaces: state.interfaces,
        error: ''
      }

    case PublicUIConstants.CREATE_PUBLIC_UI_REQUEST:
      return {
        ...state,
        fetching: true
      }

    case PublicUIConstants.UPDATE_PUBLIC_UI_REQUEST:
      return {
        ...state,
        fetching: true
      }

    case PublicUIConstants.UPDATE_PUBLIC_UI_SUCCESS:
      return {
        ...state,
        fetching: false
      }

    case PublicUIConstants.UPDATE_PUBLIC_UI_FAILURE:
      return {
        ...state,
        fetching: false
      }

    case PublicUIConstants.CREATE_PUBLIC_UI_SUCCESS:
      return {
        ...state,
        fetching: false,
        interfaces: state.interfaces.concat(payload)
      }

    case PublicUIConstants.CREATE_PUBLIC_UI_FAILURE:
      return {
        ...state,
        fetching: false,
        interfaces: state.interfaces,
        error: ''
      }

    case PublicUIConstants.FETCH_PUBLIC_UI_DATA_REQUEST:
      return {
        ...state,
        fetching: true,
        interfaceData: {}
      }

    case PublicUIConstants.FETCH_PUBLIC_UI_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        interfaceData: payload
      }

    case PublicUIConstants.FETCH_PUBLIC_UI_DATA_FAILURE:
      return {
        ...state,
        fetching: true,
        interfaceData: state.interfaceData
      }

    default:
      return state
  }
}
