import { ActorsConstants } from '../constants/actionTypes';

const initialState = {
  fetching: false,
  data: [],
  error: ''
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActorsConstants.GET_FRONTEND_SERVICES_REQUEST:
      return {
        ...state,
        fetching: true
      };

    case ActorsConstants.GET_FRONTEND_SERVICES_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: payload.actors
      };

    case ActorsConstants.GET_FRONTEND_SERVICES_FAILURE:
      return {
        ...state,
        fetching: false,
        error: payload
      };

    case ActorsConstants.CLEAR_ACTOR_DATA:
      return initialState;

    default: return state;
  }
};
