import { PermsConstants } from '../constants/actionTypes';

const initialState = {
  fetching: false,
  userData: []
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PermsConstants.FETCH_DATA_FOR_PARTICULAR_ACTOR_REQUEST:
      return {
        ...state,
        fetching: true,
        userData: [],
        error: ''
      };
    case PermsConstants.FETCH_DATA_FOR_PARTICULAR_ACTOR_SUCCESS:
      return {
        ...state,
        fetching: false,
        userData: payload,
        error: ''
      };
    case PermsConstants.FETCH_DATA_FOR_PARTICULAR_ACTOR_FAILURE:
      return {
        ...state,
        fetching: false,
        userData: []
        // userData: [],
        // error: payload
      };
    case PermsConstants.UPDATE_PERMS_FOR_SERVICE_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case PermsConstants.UPDATE_PERMS_FOR_SERVICE_SUCCESS:
      return {
        ...state,
        fetching: false,
        userData: state.userData.map(todo => todo.perm_id === payload.perm_id && todo.actor_id === payload.uuid ? { ...todo, perm_value: payload.value } : todo )
      };
    case PermsConstants.UPDATE_PERMS_FOR_SERVICE_FAILURE:
      return {
        ...state,
        fetching: false,
        userData: []
      };
    case PermsConstants.ADD_PERMS_FOR_SERVICE_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case PermsConstants.ADD_PERMS_FOR_SERVICE_SUCCESS:
      return {
        ...state,
        fetching: false,
        userData : state.userData.concat(payload.perm),
        // userData: state.userData.map(item => item.perm_id === payload.perm.perm_id && item.actor_id === payload.uuid ? { ...item, perm_value: payload.perm.perm_value }: item)
      };
    case PermsConstants.ADD_PERMS_FOR_SERVICE_FAILURE:
      return {
        ...state,
        fetching: false,
        userData: []
      };
    default: return state;
  }
};
