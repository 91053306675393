import { ActorsConstants } from '../constants/actionTypes'

const initialState = {
  fetching: true,
  actorInfo: {},
  error: ''
}

export default (state = initialState, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case ActorsConstants.FETCH_ACTORS_DATA_REQUEST:
      return {
        ...state,
        fetching: true,
        actorInfo: {},
        error: ''
      }
    case ActorsConstants.FETCH_ACTORS_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        actorInfo: payload,
        error: ''
      }
    case ActorsConstants.FETCH_ACTORS_DATA_FAILURE:
      return {
        ...state,
        fetching: false,
        actorInfo: {},
        error: ''
      }
    case ActorsConstants.UPDATE_ACTOR_REQUEST:
      return {
        ...state,
        fetching: true,
        actorInfo: state.actorInfo,
        error: ''
      }
    case ActorsConstants.UPDATE_ACTOR_SUCCESS:
      return {
        ...state,
        fetching: false,
        actorInfo: state.actorInfo
      }
    case ActorsConstants.UPDATE_ACTOR_FAILURE:
      return {
        ...state,
        fetching: false,
        actorsData: state.actorInfo
      }
    default:
      return state
  }
}
