import React, { PureComponent, lazy } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { compose } from 'redux'
import { withCookies } from 'react-cookie'
import { withRouter, Redirect } from 'react-router'

import { fetchProfileInfo, fetchListOfServices } from '../../profile/actions'
import {
  getProfileInfo,
  isProfileFetching,
  getServicesList,
  getAccess
} from '../../profile/selectors'

import { AuthHelper } from '../../auth/utils'
import ErrorBoundary from '../error/ErrorBoundary'

const ActorsContainer = lazy(() =>
  import('../../actors/containers/ActorsContainer')
)
const PublicUIContainer = lazy(() =>
  import('../../publicUI/containers/publicUIContainer')
)
const PublicUIInfoContainer = lazy(() =>
  import('../../publicUI/containers/PublicUIInfoContainer')
)
const ActorInfoContainer = lazy(() =>
  import('../../actors/containers/ActorInfoContainer')
)
const ActorPermsContainer = lazy(() =>
  import('../../actors/containers/ActorPermsContainer')
)
const LoginContainer = lazy(() =>
  import('../../auth/containers/loginContainer')
)
const SecurityPolicyContainer = lazy(() =>
  import('../../auth/containers/SecurityPolicyContainer')
)
const StartingPageContainer = lazy(() =>
  import('../../startingPage/containers/StartingPageContainer')
)
const ProfileContainer = lazy(() =>
  import('../../profile/containers/ProfileContainer')
)
const PermissionInfoContainer = lazy(() =>
  import('../../permissions/containers/PermissionInfoContainer')
)

const FooterComponent = lazy(() => import('./../../components/FooterComponent'))
const HeaderComponent = lazy(() => import('./../../components/HeaderComponent'))
const SidebarComponent = lazy(() =>
  import('./../../components/SidebarComponent')
)

class App extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isServicesDropdownOpen: false
    }

    this.fetchServices = this.fetchServices.bind(this)
  }

  fetchServices() {
    const { fetchListOfServices } = this.props
    fetchListOfServices()
  }

  componentDidMount() {
    const { fetchProfileInfo } = this.props
    try {
      AuthHelper.isAuthorised() && fetchProfileInfo()
      this.fetchServices()
    } catch (e) {
      console.error('Could not fetch services', e)
    }
  }

  render() {
    const { access, info, isFetching, availableServices } = this.props
    const pathname = window.location.href

    if (pathname.includes('?identifier') || pathname.includes('&identifier')) {
      localStorage.setItem('identifier', pathname.split('identifier=')[1])
    }

    return (
      <div className="dt-root">
        {AuthHelper.isAuthorised() ? (
          <HeaderComponent
            location={this.props.location}
            services={availableServices}
          />
        ) : null}
        <main className={`dt-main ${AuthHelper.isAuthorised() ? '' : 'mt-0'}`}>
          {AuthHelper.isAuthorised() ? (
            <SidebarComponent
              access={access}
              location={this.props.location}
              info={info}
              isFetching={isFetching}
            />
          ) : null}
          <Route exact path="/" component={() => <Redirect to="/admin/" />} />
          <Route
            exact
            path="/admin/"
            render={() => (
              <ErrorBoundary>
                <LoginContainer />
              </ErrorBoundary>
            )}
          />
          <Route
            exact
            path="/admin/auth/:authType/"
            render={() => (
              <ErrorBoundary>
                <LoginContainer />
              </ErrorBoundary>
            )}
          />
          <Route
            exact
            path="/admin/actor/:actorUuid"
            render={() =>
              AuthHelper.isAuthorised() ? (
                <ActorInfoContainer />
              ) : (
                <LoginContainer />
              )
            }
          />
          <Route
            // exact
            path="/admin/permission/:uuid"
            render={() =>
              AuthHelper.isAuthorised() ? (
                <PermissionInfoContainer />
              ) : (
                <LoginContainer />
              )
            }
          />
          <Route
            exact
            path="/admin/actor/:actorUuid/permissions"
            render={() =>
              AuthHelper.isAuthorised() ? (
                <ActorPermsContainer />
              ) : (
                <LoginContainer />
              )
            }
          />

          <Route
            exact
            path="/admin/actor/:actorUuid/info"
            render={() =>
              AuthHelper.isAuthorised() ? (
                <ActorInfoContainer />
              ) : (
                <LoginContainer />
              )
            }
          />
          <Route
            path="/admin/actors"
            render={() =>
              AuthHelper.isAuthorised() ? (
                <ErrorBoundary>
                  <ActorsContainer />
                </ErrorBoundary>
              ) : (
                <LoginContainer />
              )
            }
          />

          <Route
            exact={true}
            path="/admin/profile/"
            render={() =>
              AuthHelper.isAuthorised() ? (
                <ErrorBoundary>
                  <ProfileContainer />
                </ErrorBoundary>
              ) : (
                <LoginContainer />
              )
            }
          />
          <Route
            exact
            path="/admin/public-interfaces"
            render={() =>
              AuthHelper.isAuthorised() && AuthHelper.access() ? (
                <PublicUIContainer />
              ) : (
                <LoginContainer />
              )
            }
          />
          <Route
            exact
            path="/admin/public-interface/:uuid"
            render={() =>
              AuthHelper.isAuthorised() && AuthHelper.access() ? (
                <PublicUIInfoContainer />
              ) : (
                <LoginContainer />
              )
            }
          />
          <Route
            exact
            path="/admin/security-policy"
            render={() =>
              AuthHelper.isAuthorised() && AuthHelper.access() ? (
                <SecurityPolicyContainer />
              ) : (
                <LoginContainer />
              )
            }
          />
          <Route
            exact
            path="/admin/starting-page"
            render={() =>
              AuthHelper.isAuthorised() && AuthHelper.access() ? (
                <ErrorBoundary>
                  <StartingPageContainer />
                </ErrorBoundary>
              ) : (
                <LoginContainer />
              )
            }
          />
        </main>
        {AuthHelper.isAuthorised() ? <FooterComponent /> : null}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  access: getAccess(state),
  info: getProfileInfo(state),
  isFetching: isProfileFetching(state),
  availableServices: getServicesList(state)
})

export default compose(
  withRouter,
  withCookies,
  connect(
    mapStateToProps,
    { fetchProfileInfo, fetchListOfServices }
  )
)(App)
