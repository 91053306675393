import axios from 'axios'
import { prop } from 'ramda'

import { AuthConstants } from '../constants'
import { Notifier } from '../../perms/utils'
import { AuthHelper } from '../utils'
import { logout, unauthorized } from '../../actors/actions/actorsActions'

const localServer = 'http://192.168.1.216:5000'

const devServer = 'http://auth.new-dev.54origins.com'

export const urlAPI =
  process.env.NODE_ENV === 'development' ? localServer : window.location.origin

export function getLoginTemplateAction(callback) {
  return dispatch => {
    const url = `${urlAPI}/authorization/`

    dispatch({
      type: AuthConstants.FETCH_LOGIN_TEMPLATE_REQUEST
    })

    axios
      .get(url)
      .then(response => {
        dispatch({
          type: AuthConstants.FETCH_LOGIN_TEMPLATE_SUCCESS,
          payload: response.data
        })

        callback(response.data)

        return response
      })
      .catch(error => {
        dispatch({
          type: AuthConstants.FETCH_LOGIN_TEMPLATE_FAILURE,
          payload: error
        })

        Notifier.error(
          Boolean(Object.keys(error).length) &&
            Boolean(Object.keys(error.response).length) &&
            error.response.data.error_message
            ? error.response.data.error_message
            : error.response.data.result
            ? error.response.data.result
            : 'Authorization error'
        )
      })
  }
}

export function signUpClassicUserAction(data, redirect) {
  return dispatch => {
    const url = `${urlAPI}/reg/`

    dispatch({
      type: AuthConstants.SIGN_UP_CLASSIC_USER_REQUEST
    })

    axios
      .post(url, data)
      .then(response => {
        dispatch({
          type: AuthConstants.SIGN_UP_CLASSIC_USER_SUCCESS,
          payload: response.data
        })

        if (response.status === 200 && response.data.user) {
          const signInData = {
            actor_type: 'classic_user',
            email: data.email,
            password: data.password
          }

          const userFullName = `${
            data.uinfo.first_name ? data.uinfo.first_name : ''
          } ${data.uinfo.last_name ? data.uinfo.last_name : ''}`

          Notifier.success(`User ${userFullName} registered successfully`)

          dispatch(signInClassicUserAction(signInData, redirect))
        }

        return response
      })
      .catch(error => {
        dispatch({
          type: AuthConstants.SIGN_UP_CLASSIC_USER_FAILURE,
          payload: error
        })

        Notifier.error(error.response && error.response.data.error_message)
      })
  }
}

export function signInClassicUserAction(data, redirect) {
  return dispatch => {
    const url = `${urlAPI}/auth/`

    dispatch({
      type: AuthConstants.LOGIN_CLASSIC_USER_REQUEST
    })

    axios
      .post(url, data)
      .then(response => {
        dispatch({
          type: AuthConstants.LOGIN_CLASSIC_USER_SUCCESS,
          payload: response.data
        })

        if (response.data && response.data.redirect_to) {
          window.location.replace(response.data.redirect_to)
        }

        if (response.data.access) {
          AuthHelper.authorizedAccess(response.data.access)
        } else {
          Notifier.error('Sorry. No access')
        }

        verifyQRCode(prop('session_token', response.data))

        Notifier.success('You are successfully authorized.')

        redirect()

        return response
      })
      .catch(error => {
        dispatch({
          type: AuthConstants.LOGIN_CLASSIC_USER_FAILURE,
          payload: error
        })

        Notifier.error(
          (error.response && error.response.data.error_message) ||
            'Could not sign in with provided credentials'
        )
      })
  }
}

export function signUpForm(data) {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  return dispatch => {
    const url = `${urlAPI}/reg/`
    dispatch({
      type: AuthConstants.SIGN_UP_PROCESS_REQUEST
    })
    axios
      .post(url, data, config)
      .then(response => {
        dispatch({
          type: AuthConstants.SIGN_UP_PROCESS_SUCCESS,
          payload: response.data
        })
        Notifier.success('You are successfully registered.')
      })
      .catch(error => {
        dispatch({
          type: AuthConstants.SIGN_UP_PROCESS_FAILURE,
          payload: error
        })
        Notifier.error(error.response && error.response.data.error_message)
      })
  }
}

export function signInForm(data, callback) {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  return dispatch => {
    const url = `${urlAPI}/auth/`
    dispatch({
      type: AuthConstants.SIGN_IN_PROCESS_REQUEST
    })
    axios
      .post(url, data, config)
      .then(response => {
        dispatch({
          type: AuthConstants.SIGN_IN_PROCESS_SUCCESS,
          payload: response.data
        })
        AuthHelper.setSession(response && response.data.session_token)
        Notifier.success('You have been successfully logged in')
        response.data.access &&
          AuthHelper.authorizedAccess(response.data.access)
        response.data.redirect_to && AuthHelper.removeTemporaryToken()
        response.data.redirect_to &&
          window.location.replace(response.data.redirect_to)
        !response.data.redirect_to && callback()
      })
      .catch(error => {
        dispatch({
          type: AuthConstants.SIGN_IN_PROCESS_FAILURE,
          payload: error
        })
        Notifier.error(error.response && error.response.data.error_message)
      })
  }
}

export function getQRCode(param, callback) {
  return dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const url = `${urlAPI}/get_qr_code/${param}`

    dispatch({
      type: AuthConstants.GET_QR_CODE_REQUEST
    })
    axios
      .get(url, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: AuthConstants.GET_QR_CODE_SUCCESS,
          payload: response.data
        })

        callback(response.data)

        return response.data
      })
      .catch(error => {
        dispatch({
          type: AuthConstants.GET_QR_CODE_FAILURE,
          data: error.response ? error.response.data : {}
        })
      })
  }
}

export function getBiomNameAction() {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  return dispatch => {
    const url = `${urlAPI}/about/`
    dispatch({
      type: AuthConstants.FETCH_SERVICE_INFO_REQUEST
    })
    axios
      .get(url, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: AuthConstants.FETCH_SERVICE_INFO_SUCCESS,
          payload: {
            biomName: response.data.biom_name,
            serviceName: response.data.service_name,
            serviceUuid: response.data.service_uuid
          }
        })
      })
      .catch(error => {
        dispatch({
          type: AuthConstants.FETCH_SERVICE_INFO_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })
      })
  }
}

export function verifyQRCode(sessionToken) {
  try {
    AuthHelper.setSession(sessionToken)
  } catch (e) {
    console.log('removeSessionToken Error')
  }
  return dispatch => {
    dispatch({
      type: AuthConstants.VERIFY_QR_CODE_SUCCESS
    })
  }
}

export function getListOfPolicies(callback) {
  const token = AuthHelper.getToken()

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Session-Token': token
    }
  }

  return dispatch => {
    const url = `${urlAPI}/security_policy/`

    dispatch({ type: AuthConstants.GET_LIST_OF_POLICIES_REQUEST })

    axios
      .get(url, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: AuthConstants.GET_LIST_OF_POLICIES_SUCCESS,
          payload: {
            list: response.data.security_policy
          }
        })

        if (callback) {
          callback()
        }
      })
      .catch(error => {
        dispatch({
          type: AuthConstants.GET_LIST_OF_POLICIES_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })

        if (error.response.status === 401) {
          unauthorized(error.response.statusText)

          logout()
        }
      })
  }
}

export function sendPolicyDoc(data, callback) {
  const token = AuthHelper.getToken()
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Session-Token': token
    }
  }
  return dispatch => {
    const url = `${urlAPI}/security_policy/`

    dispatch({ type: AuthConstants.CREATE_NEW_POLICY_REQUEST })

    axios
      .post(url, data, config)
      .then(response => {
        dispatch({
          type: AuthConstants.CREATE_NEW_POLICY_SUCCESS,
          payload: response.data
        })

        callback(response.data.security_policy)

        Notifier.success('Document has been successfully created')
      })
      .catch(error => {
        dispatch({
          type: AuthConstants.CREATE_NEW_POLICY_FAILURE,
          payload: error
        })

        Notifier.error(error.response && error.response.data.error_message)

        if (error.response.status === 401) {
          unauthorized(error.response.statusText)

          logout()
        }
      })
  }
}

export function getAuthLoginRedirect(callback) {
  return dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const url = `${urlAPI}/auth_authorization/`

    dispatch({
      type: AuthConstants.GET_REDIRECT_URL_REQUEST
    })

    axios
      .get(url, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: AuthConstants.GET_REDIRECT_URL_SUCCESS,
          data: response.data
        })

        AuthHelper.setCookies(
          'temporary_session',
          response.data.temporary_session
        )

        callback(response.data.domain)
      })
      .catch(error => {
        dispatch({
          type: AuthConstants.GET_REDIRECT_URL_FAILURE,
          data: error.response ? error.response.data : {}
        })
      })
  }
}
