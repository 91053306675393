import keyMirror from 'keymirror'

export const PermissionConstants = keyMirror({
  GET_PERMISSION_INFO_REQUEST: null,
  GET_PERMISSION_INFO_SUCCESS: null,
  GET_PERMISSION_INFO_FAILURE: null,

  REMOVE_PERMISSION_REQUEST: null,
  REMOVE_PERMISSION_SUCCESS: null,
  REMOVE_PERMISSION_FAILURE: null
})
