import axios from 'axios'

import { ActorsConstants } from '../constants/actionTypes'
import { AuthHelper } from '../../auth/utils'
import { history } from '../../store'
import { Notifier } from '../../perms/utils'
import { urlAPI } from '../../auth/actions'

const getConfig = () => ({
  headers: {
    'Content-Type': 'application/json',
    'Session-Token': AuthHelper.getToken()
  }
})

// TODO: error handler for each action

export const unauthorized = errorMessage => {
  try {
    AuthHelper.removeSessionToken('Auth')

    history.push('/admin/')

    Notifier.error(errorMessage, 'Warning')
  } catch (e) {
    console.log('Error')
  }
}

export const logout = () => {
  try {
    AuthHelper.removeSessionToken('Auth')
    AuthHelper.clearAuthStorage()

    history.push('/admin/')
  } catch (e) {
    console.log('Error')
  }
}

export function createNewActor(data, callback) {
  const config = getConfig()

  const type =
    data.actor_type.charAt(0).toUpperCase() + data.actor_type.slice(1)

  return dispatch => {
    const url = `${urlAPI}/create/actor`

    dispatch({ type: ActorsConstants.CREATE_ACTOR_REQUEST })

    axios
      .post(url, data, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: ActorsConstants.CREATE_ACTOR_SUCCESS,
          payload: response.data
        })
        Notifier.success(
          response.data.message || `${type} has been created successfully`
        )

        callback()
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.CREATE_ACTOR_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })

        Notifier.error(
          error.response.data.error_message || 'Something went wrong'
        )

        if (error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        }
      })
  }
}

export function getFrontServicesByBackEndServiceUuidAction(uuid) {
  const config = getConfig()

  const params = {
    actor_type: 'front_service',
    uinfo: {
      service_uuid: uuid
    }
  }

  return dispatch => {
    const url = `${urlAPI}/get/actors`

    dispatch({ type: ActorsConstants.GET_FRONTEND_SERVICES_REQUEST })

    axios
      .post(url, params, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: ActorsConstants.GET_FRONTEND_SERVICES_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.GET_FRONTEND_SERVICES_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })

        Notifier.error(error.response.data.error_message)

        if (error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        }
      })
  }
}

export function getBackEndServicesAction() {
  const config = getConfig()

  const params = {
    actor_type: ['service']
  }

  return dispatch => {
    const url = `${urlAPI}/get/actors`

    dispatch({ type: ActorsConstants.GET_BACKEND_SERVICES_REQUEST })

    axios
      .post(url, params, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: ActorsConstants.GET_BACKEND_SERVICES_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.GET_BACKEND_SERVICES_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })

        Notifier.error(error.response.data.error_message)

        if (error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        }
      })
  }
}

export function getPartitionsAction(uuid) {
  const config = getConfig()

  return dispatch => {
    const url = `${urlAPI}/get/partition_info/${uuid}`

    dispatch({ type: ActorsConstants.GET_PARTITIONS_REQUEST })

    axios
      .get(url, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: ActorsConstants.GET_PARTITIONS_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.GET_PARTITIONS_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })

        Notifier.error(error.response.data.error_message)

        if (error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        }
      })
  }
}

export function filterByGroup(groups) {
  return dispatch => {
    dispatch({
      type: ActorsConstants.FILTER_BY_GROUP,
      payload: groups
    })
  }
}

export function fetchActorGroups(data) {
  const config = getConfig()

  const params = {
    actor_type: ['group']
  }

  return dispatch => {
    const url = `${urlAPI}/get/actors`

    dispatch({ type: ActorsConstants.FETCH_ACTOR_GROUPS_REQUEST })

    axios
      .post(url, params, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: ActorsConstants.FETCH_ACTOR_GROUPS_SUCCESS,
          payload: response.data.actors
        })
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.FETCH_ACTOR_GROUPS_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })

        if (error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        }
      })
  }
}

export function fetchActors(data) {
  const config = getConfig()

  return dispatch => {
    const url = `${urlAPI}/get/actors`

    dispatch({ type: ActorsConstants.FETCH_ALL_ACTORS_REQUEST })

    axios
      .post(url, data, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: ActorsConstants.FETCH_ALL_ACTORS_SUCCESS,
          payload: response.data.actors
        })
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.FETCH_ALL_ACTORS_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })

        if (error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        }
      })
  }
}

export function fetchGroupsOfUser(data, callback) {
  const config = getConfig()

  return dispatch => {
    const url = `${urlAPI}/get/actors`

    dispatch({ type: ActorsConstants.FETCH_ACTORS_GROUPS_REQUEST })

    axios
      .post(url, data, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: ActorsConstants.FETCH_ACTORS_GROUPS_SUCCESS,
          payload: response.data.actors
        })

        callback()
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.FETCH_ACTORS_GROUPS_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })

        if (error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        }
      })
  }
}

export function clearActorDataAction() {
  return dispatch => {
    dispatch({ type: ActorsConstants.CLEAR_ACTOR_DATA })
  }
}

export function fetchUsersInGroup(data, callback) {
  const config = getConfig()

  return dispatch => {
    const url = `${urlAPI}/get/actors`

    const params = {
      uinfo: {
        groups: [data.uuid]
      }
    }

    dispatch({ type: ActorsConstants.FETCH_ACTORS_IN_GROUP_REQUEST })

    axios
      .post(url, params, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: ActorsConstants.FETCH_ACTORS_IN_GROUP_SUCCESS,
          payload: response.data.error ? [] : response.data.actors,
          error: response.data.error_message
        })

        callback && callback()
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.FETCH_ACTORS_IN_GROUP_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })

        if (error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        }
      })
  }
}

export function fetchActorsData(uuid, callback) {
  const config = getConfig()

  const params = {}

  return dispatch => {
    const url = `${urlAPI}/get/actor/${uuid}`

    dispatch({ type: ActorsConstants.FETCH_ACTORS_DATA_REQUEST })

    axios
      .post(url, params, config, { withCredentials: true })
      .then(response => {
        const payload = {
          ...response.data.actor,
          uinfo: {
            ...response.data.actor.uinfo,
            groups: response.data.groups
          }
        }

        dispatch({
          type: ActorsConstants.FETCH_ACTORS_DATA_SUCCESS,
          payload
        })

        callback(payload)
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.FETCH_ACTORS_DATA_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })

        if (error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        }
      })
  }
}

export function updateActor(data, callback) {
  const config = getConfig()

  return dispatch => {
    const url = `${urlAPI}/update/actor`

    dispatch({ type: ActorsConstants.UPDATE_ACTOR_REQUEST })

    axios
      .post(url, data, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: ActorsConstants.UPDATE_ACTOR_SUCCESS,
          payload: {
            actor: response.data.actor,
            message: response.data.message
          }
        })

        callback()

        Notifier.success(
          response.data.message || 'Actor has been updated successfully'
        )
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.UPDATE_ACTOR_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })

        Notifier.error(
          error.response.data.error_message || 'Actor has not been updated'
        )

        if (error.response && error.response.status === 403) {
          Notifier.error('Permission denied')
        }

        if (error.response && error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        }
      })
  }
}

export function getConnectionsPhantom(data) {
  const config = getConfig()

  return dispatch => {
    const url = `${urlAPI}/get/relations/phantom`

    dispatch({ type: ActorsConstants.FETCH_PHANTOM_CONNECTIONS_REQUEST })
    axios
      .post(url, data, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: ActorsConstants.FETCH_PHANTOM_CONNECTIONS_SUCCESS,
          payload: {
            relations: response.data.phantom_result
          }
        })
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.FETCH_PHANTOM_CONNECTIONS_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })

        if (error.response && error.response.status === 403) {
          Notifier.error('Permission denied')
        }

        if (error && error.response && error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        } else {
          Notifier.error('Could not get phantom relations', error)
        }
      })
  }
}

export function removePhantomRelation(data) {
  const config = getConfig()

  return dispatch => {
    const url = `${urlAPI}/delete/relations/phantom`

    dispatch({ type: ActorsConstants.REMOVE_PHANTOM_RELATION_REQUEST })
    axios
      .post(url, data, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: ActorsConstants.REMOVE_PHANTOM_RELATION_SUCCESS,
          payload: {
            removedRelations: response.data.phantom_relations
          }
        })

        Notifier.success(response && response.data.message)
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.REMOVE_PHANTOM_RELATION_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })

        Notifier.error(
          (error && error.response.data.error_message) ||
            'Relation has not been removed'
        )

        if (error.response && error.response.status === 403) {
          Notifier.error('Permission denied')
        }

        if (error && error.response && error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        } else {
          Notifier.error('Could not remove phantom relation', error)
        }
      })
  }
}

export function createPhantomConnection(data, callback) {
  const config = getConfig()

  return dispatch => {
    const url = `${urlAPI}/create/relations/phantom`

    dispatch({ type: ActorsConstants.CREATE_PHANTOM_CONNECTION_REQUEST })
    axios
      .post(url, data, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: ActorsConstants.CREATE_PHANTOM_CONNECTION_SUCCESS,
          payload: {
            relationToAdd: response.data.phantom_result
          }
        })

        Notifier.success(response && response.data.message)
        if (callback) {
          callback()
        }
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.CREATE_PHANTOM_CONNECTION_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })

        Notifier.error(
          (error && error.response.data.error_message) ||
            'Phantom has not been connected'
        )

        if (error.response && error.response.status === 403) {
          Notifier.error('Permission denied')
        }

        if (error && error.response && error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        } else {
          Notifier.error('Could not create phantom relation', error)
        }
      })
  }
}

export function fetchActorPermissions(data) {
  const config = getConfig()

  return dispatch => {
    const url = `${urlAPI}/get/custom_filter/permissions`

    dispatch({ type: ActorsConstants.FETCH_ACTORS_PERMS_REQUEST })

    axios
      .post(url, data, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: ActorsConstants.FETCH_ACTORS_PERMS_SUCCESS,
          payload: { data: response.data.permissions, uuid: data.uuid }
        })
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.FETCH_ACTORS_PERMS_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })

        if (error && error.response && error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        } else {
          Notifier.error('Could not fetch actor permissions', error)
        }
      })
  }
}

export function removePermission(params) {
  const config = getConfig()

  const data = { uuid: params.perm.uuid }

  return dispatch => {
    const url = `${urlAPI}/delete/permission`

    dispatch({ type: ActorsConstants.REMOVE_PERM_REQUEST })

    axios
      .post(url, data, config)
      .then(response => {
        dispatch({
          type: ActorsConstants.REMOVE_PERM_SUCCESS,
          payload: {
            perm_id: params.perm.perm_id,
            perm: params.perm,
            uuid: params.actor_id
          }
        })

        Notifier.success(response.data.message)
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.REMOVE_PERM_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })

        if (error && error.response && error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        } else {
          Notifier.error('Could not remove permission', error)
        }
      })
  }
}

export function removePermissions(data) {
  const config = getConfig()

  return dispatch => {
    const url = `${urlAPI}/delete/permissions`

    dispatch({ type: ActorsConstants.REMOVE_PERM_REQUEST })

    axios
      .post(url, data, config)
      .then(response => {
        dispatch({
          type: ActorsConstants.REMOVE_PERM_SUCCESS
        })

        Notifier.success(response.data.message)
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.REMOVE_PERM_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })

        if (error && error.response && error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        } else {
          Notifier.error('Could not remove permission', error)
        }
      })
  }
}

export function assignPermission(
  data,
  {
    action_id,
    actor_id,
    default_value,
    description,
    perm_id,
    perm_type,
    perm_value,
    service_id,
    uuid
  }
) {
  const config = getConfig()

  const params = {
    permission: {
      action_id,
      actor_id,
      default_value,
      description,
      perm_id,
      perm_type,
      perm_value,
      service_id
    }
  }

  return dispatch => {
    const url = `${urlAPI}/set/permission`

    dispatch({ type: ActorsConstants.ADD_PERM_REQUEST })

    axios
      .post(url, params, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: ActorsConstants.ADD_PERM_SUCCESS,
          payload: {
            defaultChangedPerm: { ...params.permission, uuid },
            newPerm: response.data.permission,
            message: response.data.message
          }
        })

        Notifier.success(response.data.message)
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.ADD_PERM_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })

        if (error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        }
      })
  }
}

export function assignPermissions(permissions) {
  const config = getConfig()

  return dispatch => {
    const url = `${urlAPI}/set/permissions`

    dispatch({ type: ActorsConstants.ADD_PERMISSIONS_REQUEST })

    axios
      .post(url, permissions, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: ActorsConstants.ADD_PERMISSIONS_SUCCESS
        })

        Notifier.success(response.data.message)
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.ADD_PERMISSIONS_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })

        if (error && error.response && error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        } else {
          Notifier.error('Could not update permissions', error)
        }
      })
  }
}

export function updateAssignedPermission(newPermObj) {
  const config = getConfig()

  const params = {
    permission: {
      action_id: newPermObj.action_id,
      actor_id: newPermObj.actor_id,
      default_value: newPermObj.default_value,
      description: newPermObj.description,
      perm_id: newPermObj.perm_id,
      perm_type: newPermObj.perm_type,
      perm_value: newPermObj.perm_value,
      service_id: newPermObj.service_id
    }
  }

  return dispatch => {
    const url = `${urlAPI}/set/permission`

    dispatch({ type: ActorsConstants.UPDATE_ASSIGNED_PERM_REQUEST })

    axios
      .post(url, params, config, { withCredentials: true })
      .then(response => {
        dispatch({
          type: ActorsConstants.UPDATE_ASSIGNED_PERM_SUCCESS,
          payload: {
            perm: newPermObj,
            value: Number(newPermObj.perm_value),
            message: response.data.message
          }
        })

        Notifier.success(response.data.message)
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.UPDATE_ASSIGNED_PERM_FAILURE,
          payload: error.response ? error.response.data.message : {}
        })

        if (error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        }
      })
  }
}

export function deleteActor(uuid) {
  const config = getConfig()

  const data = { uuid }

  return dispatch => {
    const url = `${urlAPI}/delete/actor`

    dispatch({ type: ActorsConstants.REMOVE_ACTOR_REQUEST })

    axios
      .post(url, data, config)
      .then(response => {
        dispatch({
          type: ActorsConstants.REMOVE_ACTOR_SUCCESS,
          payload: {
            uuid,
            message: response.data.message
          }
        })

        Notifier.success(response.data.message)
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.REMOVE_ACTOR_FAILURE,
          payload: error.response ? error.response.data.error_message : {}
        })

        if (error && error.response && error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        } else {
          Notifier.error(
            (error &&
              error.response &&
              error.response.data &&
              error.response.data.error_message) ||
              'Could not delete actor'
          )
        }
      })
  }
}

export function deleteActorBulk(data) {
  const config = getConfig()

  return dispatch => {
    const url = `${urlAPI}/delete/actor`

    dispatch({ type: ActorsConstants.REMOVE_ACTORS_REQUEST })

    axios
      .post(url, data, config)
      .then(response => {
        dispatch({
          type: ActorsConstants.REMOVE_ACTORS_SUCCESS,
          payload: {
            data,
            message: response.data.message
          }
        })

        Notifier.success(response.data.message)
      })
      .catch(error => {
        dispatch({
          type: ActorsConstants.REMOVE_ACTORS_REQUEST,
          payload: error.response ? error.response.data.error_message : {}
        })

        if (error && error.response && error.response.status === 401) {
          unauthorized(error.response.statusText)
          logout()
        } else {
          Notifier.error(
            (error &&
              error.response &&
              error.response.data &&
              error.response.data.error_message) ||
              'Could not delete actors'
          )
        }
      })
  }
}
