import keyMirror from 'keymirror';

export const ProfileConstants = keyMirror({

  FETCH_PROFILE_INFO_REQUEST: null,
  FETCH_PROFILE_INFO_SUCCESS: null,
  FETCH_PROFILE_INFO_FAILURE: null,

  UPDATE_PROFILE_INFO_REQUEST: null,
  UPDATE_PROFILE_INFO_SUCCESS: null,
  UPDATE_PROFILE_INFO_FAILURE: null,

  FETCH_SERVICES_REQUEST: null,
  FETCH_SERVICES_SUCCESS: null,
  FETCH_SERVICES_FAILURE: null,

})
