// @flow weak

import { routerReducer } from 'react-router-redux'
import { combineReducers } from 'redux'

import authReducer from '../auth/reducers/getQRCodeReducer'
import verifyQRCodeReducer from '../auth/reducers/verifyQRCodeReducer'
import getPrevLocationReducer from '../auth/reducers/getPrevLocationReducer'
import getBiomNameReducer from '../auth/reducers/getBiomNameReducer'
import getQRtoDownloadReducer from '../auth/reducers/getQRtoDownloadReducer'

import fetchProfileInfoReducer from '../profile/reducers/fetchProfileInfoReducer'
import fetchServicesReducer from '../profile/reducers/fetchServicesReducer'

import getListOfPoliciesReducer from '../auth/reducers/getListOfPoliciesReducer'
import createNewPolicyReducer from '../auth/reducers/createNewPolicyReducer'
import backEndServicesReducer from '../actors/reducers/backEndServicesReducer'
import frontEndServicesReducer from '../actors/reducers/frontEndServicesReducer'
import partitionsReducer from '../actors/reducers/partitionsReducer'

import permsListReducer from '../perms/reducers/permsListReducer'
import selectedActionReducer from '../perms/reducers/selectedActionReducer'
import actorsDataReducer from '../actors/reducers/actorsDataReducer'
import actorsInfoReducer from '../actors/reducers/actorsInfoReducer'
import phantomRelations from '../actors/reducers/phantomRelationsReducer'
import actorListOfPermissionsReducer from '../actors/reducers/actorListOfPermissionsReducer'
import actorGroupsDataReducer from '../actors/reducers/actorGroupsDataReducer'
import actorInGroupReducer from '../actors/reducers/actorInGroupReducer'
import userDataReducer from '../perms/reducers/actorDataReducer'
import serviceInfoReducer from '../actors/reducers/serviceDataReducer'
import publicUIReducer from '../publicUI/reducers/index'
import permissionInfoReducer from '../permissions/reducer'

export const reducers = {
  selectedActionReducer
}

export default combineReducers({
  ...reducers,
  auth: combineReducers({
    qrCode: authReducer,
    codeToDownloadApp: getQRtoDownloadReducer,
    serviceInfo: getBiomNameReducer,
    confirmationProcess: verifyQRCodeReducer,
    location: getPrevLocationReducer,
    policy: combineReducers({
      listOfPolicies: getListOfPoliciesReducer,
      createNewPolicy: createNewPolicyReducer
    })
  }),
  perms: combineReducers({
    listOfPerms: permsListReducer,
    selectedAction: selectedActionReducer
  }),
  actors: combineReducers({
    listOfActors: actorsDataReducer,
    backEndServices: backEndServicesReducer,
    frontEndServices: frontEndServicesReducer,
    partitions: partitionsReducer,
    type: 'user'
  }),
  profile: combineReducers({
    info: fetchProfileInfoReducer,
    services: fetchServicesReducer
  }),
  publicUI: combineReducers({
    interfacesList: publicUIReducer
  }),
  permissionInfo: combineReducers({
    actorsList: permissionInfoReducer
  }),
  actorInfo: combineReducers({
    data: actorsInfoReducer,
    perms: actorListOfPermissionsReducer,
    groups: actorGroupsDataReducer,
    actorsInGroup: actorInGroupReducer,
    phantomRelations: phantomRelations
  }),
  serviceInfo: combineReducers({
    data: serviceInfoReducer
  }),
  userData: userDataReducer,
  routing: routerReducer
})
