import {AuthConstants} from "../constants/index";


const initialState = {
  fetching: false,
  biomName: '',
  serviceName: '',
  serviceUuid: ''
};

export default (state=initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case AuthConstants.FETCH_SERVICE_INFO_REQUEST:
      return {
        ...state,
        fetching: true,
        biomName: '',
        serviceName: '',
        serviceUuid: ''
      };
    case AuthConstants.FETCH_SERVICE_INFO_SUCCESS:
      return {
        ...state,
        fetching: false,
        biomName:  payload.biomName,
        serviceName: payload.serviceName,
        serviceUuid: payload.serviceUuid
      };
    case AuthConstants.FETCH_SERVICE_INFO_FAILURE:
      return {
        ...state,
        fetching: false,
        biomName: '',
        serviceName: '',
        serviceUuid: ''
      };

    default:  return state;
  }
};
