import { ActorsConstants } from '../constants/actionTypes'

const initialState = {
  fetching: false,
  fetchingGroups: false,
  actors: [],
  actorGroups: [],
  groupFilter: [],
  error: '',
  groupsError: ''
}

export default (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    // GET ACTOR LIST

    case ActorsConstants.FETCH_ALL_ACTORS_REQUEST:
      return {
        ...state,
        fetching: true,
        actors: []
      }

    case ActorsConstants.FETCH_ALL_ACTORS_SUCCESS:
      return {
        ...state,
        fetching: false,
        actors: payload
      }

    case ActorsConstants.FETCH_ALL_ACTORS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: ''
      }

    // GET ACTOR GROUPS

    case ActorsConstants.FETCH_ACTOR_GROUPS_REQUEST:
      return {
        ...state,
        fetchingGroups: true,
        actorGroups: []
      }

    case ActorsConstants.FETCH_ACTOR_GROUPS_SUCCESS:
      return {
        ...state,
        fetchingGroups: false,
        actorGroups: payload
      }

    case ActorsConstants.FETCH_ACTOR_GROUPS_FAILURE:
      return {
        ...state,
        fetchingGroups: false,
        groupsError: ''
      }

    // FILTER BY GROUP

    case ActorsConstants.FILTER_BY_GROUP:
      return {
        ...state,
        groupFilter: payload
      }

    // REMOVE ACTOR

    case ActorsConstants.REMOVE_ACTOR_REQUEST:
      return {
        ...state,
        fetching: true
      }

    case ActorsConstants.REMOVE_ACTOR_SUCCESS:
      return {
        ...state,
        fetching: false,
        actors: state.actors.filter(item => item.uuid !== payload.uuid)
      }

    case ActorsConstants.REMOVE_ACTOR_FAILURE:
      return {
        ...state,
        fetching: false,
        actors: state.actors,
        error: ''
      }

    // REMOVE ACTORS BULK

    case ActorsConstants.REMOVE_ACTORS_REQUEST:
      return {
        ...state,
        fetching: true
      }

    case ActorsConstants.REMOVE_ACTORS_SUCCESS:
      return {
        ...state,
        fetching: false,
        actors: state.actors.filter(
          actor => payload.data.uuid_list.indexOf(actor.uuid) === -1
        )
      }

    case ActorsConstants.REMOVE_ACTORS_FAILURE:
      return {
        ...state,
        fetching: false,
        actors: state.actors,
        error: ''
      }

    default:
      return state
  }
}
