import _ from 'lodash';

export const Notifier = (function() {
  const apply_styles = function(element, style_object) {
    for (const prop in style_object)
      if (style_object.hasOwnProperty(prop))
        element.style[prop] = style_object[prop];
  };

  const fade_out = function(element) {
    if (element.style.opacity && element.style.opacity > 0.05) {
      element.style.opacity = element.style.opacity - 0.05;
    } else if (element.style.opacity && element.style.opacity <= 0.1) {
      if (element.parentNode) {
        element.parentNode.removeChild(element);
      }
    } else {
      element.style.opacity = 0.9;
    }
    setTimeout(function() {
      fade_out.apply(this, [element]);
    }, 1000 / 30);
  };

  const config = {
    default_timeout: 5000,
    /* container for the notifications */
    container: document.createElement('div'),
    /* container styles for notifications */
    container_styles: {
      position: "fixed",
      zIndex: 99999,
      right: "12px",
      top: "20px"
    },
    box_styles: {
      cursor: "pointer",
      padding: "18px 18px",
      margin: "0 0 6px 0",
      backgroundColor: "#000",
      opacity: 0.8,
      color: "#fff",
      font: "normal 15px 'Lucida Sans Unicode', 'Lucida Grande', Verdana, Arial, Helvetica, sans-serif",
      borderRadius: "3px",
      boxShadow: "#999 0 0 12px",
      width: "300px"
    },
    /* individual notification box hover styles */
    box_styles_hover: {
      opacity: 1,
      boxShadow: "#000 0 0 12px"
    },
    /* notification title text styles */
    title_styles: {
      fontWeight: "700"
    },
    /* notification body text styles */
    text_styles: {
      wordWrap: 'break-word'
    },
    /* notification icon styles */
    icon_styles: {
      display: "inline-block",
      verticalAlign: "middle",
      height: "36px",
      width: "36px"
    }
  };

  apply_styles(config.container, config.container_styles);

  document.body.appendChild(config.container);

  return {
    notify: function(message, title, backgroundColor) {
      let notification = document.createElement('div');
      notification.className = 'row';

      apply_styles(notification, _.merge({}, config.box_styles, {backgroundColor: backgroundColor}));

      notification.onmouseover = function() {
        apply_styles(this, config.box_styles_hover);
      };

      notification.onmouseout = function() {
        apply_styles(this, _.merge({}, config.box_styles, {backgroundColor: backgroundColor}));
      };

      notification.onclick = function() {
        this.style.display = 'none';
      };

      let iconContainer = document.createElement('div');
      iconContainer.className = 'col-xs-2 no-padding';


      notification.appendChild(iconContainer);

      let text = document.createElement('div');
      text.className = 'col-xs-10 no-padding';

      notification.appendChild(text);

      if (title) {
        let title_text = document.createElement('div');
        apply_styles(title_text, config.title_styles);
        title_text.appendChild(document.createTextNode(title));
        text.appendChild(title_text);
      }

      if (message) {
        let message_text = document.createElement('span');
        message_text.appendChild(document.createTextNode(message));
        message_text.innerHTML = message;
        apply_styles(message_text, config.text_styles);
        text.appendChild(message_text);
      }

      config.container.insertBefore(notification, config.container.firstChild);

      setTimeout(function() {
        fade_out(notification);
      }, config.default_timeout);
    },
    info: function(message, title) {
      this.notify(message, title, '#20a8d8');
    },
    warning: function(message, title) {
      this.notify(message, title, '#f7c94c');
    },
    success: function(message, title) {
      this.notify(message, title, '#4dbd74');
    },
    error: function(message, title) {
      this.notify(message, title, '#b21919');
    }
  };
}());
