import keyMirror from 'keymirror'

export const PublicUIConstants = keyMirror({
  FETCH_ALL_PUBLIC_UI_REQUEST: null,
  FETCH_ALL_PUBLIC_UI_SUCCESS: null,
  FETCH_ALL_PUBLIC_UI_FAILURE: null,

  REMOVE_PUBLIC_UI_REQUEST: null,
  REMOVE_PUBLIC_UI_SUCCESS: null,
  REMOVE_PUBLIC_UI_FAILURE: null,

  UPDATE_PUBLIC_UI_REQUEST: null,
  UPDATE_PUBLIC_UI_SUCCESS: null,
  UPDATE_PUBLIC_UI_FAILURE: null,

  FETCH_PUBLIC_UI_DATA_REQUEST: null,
  FETCH_PUBLIC_UI_DATA_SUCCESS: null,
  FETCH_PUBLIC_UI_DATA_FAILURE: null,

  CREATE_PUBLIC_UI_REQUEST: null,
  CREATE_PUBLIC_UI_SUCCESS: null,
  CREATE_PUBLIC_UI_FAILURE: null
})
