import { PermsConstants } from '../constants/actionTypes';

const initialState = {
  fetching: false,
  perms: []
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PermsConstants.FETCH_ALL_PERMS_REQUEST:
      return {
        ...state,
        fetching: true,
        perms: payload,
        error: ''
      };
    case PermsConstants.FETCH_ALL_PERMS_SUCCESS:
      return {
        ...state,
        fetching: false,
        perms: payload,
        error: ''
      };
    case PermsConstants.FETCH_ALL_PERMS_FAILURE:
      return {
        ...state,
        fetching: false,
        perms: payload // ,
        // error: payload
      };
    default: return state;
  }
};
